.ant-input-affix-wrapper {
    border: none;
    border-radius: 0%;

    .ant-input-suffix {
        margin-right: 10px;
    }
}

.input {
    padding: 15px 0;
    border-bottom: 1px solid #e5e5e5 !important;

    img {
        width: 28px;
        height: 28px;
        margin-right: 6px
    }
}

.input:focus-within {
    border-bottom: 1px solid black !important;
}

.search {
    width: calc(100% - 12px);
    padding: 8px 8px;
    border: 1px solid #e5e5e5 !important;
    margin: 10px 0;
}

.search:focus-within {
    border: 1px solid black !important;
}

.blackBody {
    .ant-layout-sider {

        .ant-input {
            color: white;
        }

        .input {
            padding: 15px 0;
            border-bottom: 1px solid #2e2e2e !important;

            img {
                width: 28px;
                height: 28px;
                margin-right: 6px
            }
        }

        .search {
            border: 1px solid #2e2e2e !important;

            .ant-input-suffix {
                .anticon {
                    background-color: white;
                    border-radius: 50%;
                    color: black;
                }
            }
        }

        .search:focus-within {
            border: 1px solid #c9c6c6 !important;
        }
    }
}
@primary-color: #1890ff;