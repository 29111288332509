.treasure-nft {
    padding-top: calc(100% / 0.85);
    background-color: #f8e51a;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    background-image: url(../../images/qzuki/logo.png);
    background-size: cover;
    background-position: center;

    .treasure-interface {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
        }

        .icon {
            position: absolute;
            top: 5px;
            right: 5px;
            color: black;
            border-radius: 50%;
            background-color: #cfccc7;
        }
    }
}

.treasure-amplify:hover {
    .treasure-interface {
        top: -2.5%;
        left: -2.5%;
        width: 105%;
        height: 105%;
    }
}

.occupyBitmap-shadow {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2) inset;
}

.showSelectedStatus {
    box-shadow: 0 0 0 2px #e1cf00, 0 0 2px 2px rgba(0, 0, 0, 0.2);
}
@primary-color: #1890ff;