.ant-modal-confirm {
    .ant-modal-content {
        border-radius: 20px;
        background-color: #ece9e2;
    }

    .ant-modal-body {
        padding: 25px 35px;

        .ant-modal-confirm-body {
            .ant-modal-confirm-content {
                font-size: 18px;
                font-family: pixelfont;
                font-weight: 900;
                margin: 0;
                text-align: center;
            }
        }
    }
}

.personal-modal .ant-modal-content {

    .ant-modal-confirm-body .anticon,
    .ant-modal-confirm-btns {
        display: none;
    }
}

.ant-modal-confirm-success .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content {
    background: linear-gradient(-45deg, #f00404, #f15d23, #f5cb12, #f4ae35);
    background-clip: text;
    background-size: 200% 200%;
    color: transparent;
    -webkit-animation: gradient 2s ease-in-out infinite alternate;
    animation: gradient 2s ease-in-out infinite alternate;
}


.ant-modal-confirm-error .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content {
    color: #4e99a1;
}

.Personal-Center-overflow {
    overflow: auto !important;

    .Personal-Center {
        width: 100%;
        position: relative;
        margin-top: 72px;

        .header-banner {
            position: relative;

            img {
                width: 100%;
            }

            .ant-btn {
                position: absolute;
                right: 3vw;
                bottom: 3vw;
                background: linear-gradient(90deg, #6390fe, #af4ef9, #fe997f);
                background-size: 200% 200%;
                color: white;
                border: none;
                border-radius: 5px;
                -webkit-animation: gradient 2s ease-in-out infinite alternate;
                animation: gradient 2s ease-in-out infinite alternate;
            }
        }

        .header-User {
            margin-top: 30px;
            padding: 0 3vw;
            display: flex;
            align-items: center;

            .header-User-Left {
                width: 60%;
                height: 100%;

                .User {
                    position: relative;
                    display: flex;
                    justify-content: space-between;

                    .ant-input-wrapper {
                        .ant-input-affix-wrapper {
                            border: 1px solid #d9d9d9;
                        }

                        .ant-input-group-addon {
                            border-left: 1px solid #d9d9d9;

                            .ant-btn {
                                border-radius: 20px;
                                font-size: 12px;
                                height: 30px;
                                margin-left: 15px;
                                background-color: black;
                                color: white;
                                border: none;
                            }
                        }
                    }

                    .ant-input-affix-wrapper:focus,
                    .ant-input-affix-wrapper-focused {
                        box-shadow: none;
                    }

                    h1 {
                        font-size: 1rem;
                        font-weight: 900;
                        margin: 0;
                        line-height: 30px;

                        img {
                            width: 20px;
                            border-radius: 5px;
                            margin-top: -5px;
                            margin-left: 15px;
                            cursor: pointer;
                        }
                    }

                    .wallet {
                        margin-top: 15px;
                        cursor: pointer;
                        max-width: fit-content;

                        p {
                            margin-bottom: 0;
                            font-family: pixelfont;
                            font-size: 0.45rem;
                        }

                        .fuzhi {
                            width: 15px;
                            height: 15px;
                            margin: 0 0 0 10px;
                            background-color: black;
                            border: 2px solid #f5e528;
                            box-shadow: 3px -2px 1px #7b7c79;
                        }
                    }

                    .twitter-share {
                        height: fit-content;
                        padding: 4px 15px !important;
                        background-color: #4578f6 !important;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 5px;
                        color: white !important;
                        cursor: pointer;

                        span {
                            margin-left: 8px;
                        }
                    }
                }

                .treasure {
                    margin-top: 20px;

                    h1 {
                        font-size: 0.7rem;
                        font-weight: 900;

                        img {
                            width: 18px;
                            border-radius: 5px;
                            margin-top: -4px;
                            margin-left: 5px;
                            cursor: pointer;
                        }
                    }

                    .display-treasure-nfts {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                        grid-gap: 0 2%;
                    }
                }
            }

            .header-User-Right {
                width: 35%;
                height: 100%;
                margin-left: 5%;
                position: relative;

                .badge-title {
                    h1 {
                        margin: 0;
                        font-weight: 900;
                        font-size: 0.7rem;
                    }

                    .ant-btn {
                        background-color: #f6f6f6;
                        border-radius: 5px;
                        border: none;
                        font-weight: 900;
                        color: black;
                    }
                }

                .badges {
                    width: 100%;
                    margin-top: 10px;

                    .more {
                        padding-top: 100%;
                        float: left;
                        position: relative;
                        cursor: pointer;
                        border-radius: 10px;
                        background-color: #cccccc;
                        display: flex;
                        justify-content: center;

                        p {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-55%);
                            margin: 0;
                            font-size: 1rem;
                            font-weight: 900;
                        }
                    }
                }

                .badges-gird {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                    grid-gap: 10px 10px;
                }
            }
        }

        //解决子元素设置float，父元素高度坍塌的问题
        .header-User .header-User-Left .treasure::after,
        .header-User .header-User-Right .badges::after {
            content: "";
            display: block;
            height: 0;
            clear: both;
            visibility: hidden;
        }

        .banners {
            width: 100%;
            margin-top: 40px;
            padding: 0 3vw;
            position: relative;

            .banners-header {
                width: 100%;

                h1 {
                    font-size: 0.7rem;
                    font-weight: 900;
                }

                .Cherish {
                    .ant-btn {
                        background-color: #f6f6f6;
                        border-radius: 8px;
                        border: none;
                        font-weight: 900;
                        color: rgba(0, 0, 0, 0.5);
                        margin-right: 15px;
                    }

                    .bannersSelect {
                        background-color: #f8e51a;
                        color: rgba(0, 0, 0, 1);
                    }
                }

                .ant-btn {
                    background-color: #f6f6f6;
                    border-radius: 5px;
                    border: none;
                    font-weight: 900;
                    color: black;
                }
            }

            .banner {
                width: 100%;
                position: relative;
                margin-top: 20px;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-gap: 3% 3%;
            }
        }

        .QZUKI {
            width: 100%;
            padding: 0 3vw calc(3vw + 50px) 3vw;
            margin-top: 30px;

            h1 {
                font-size: 0.7rem;
                font-weight: 900;
                margin-right: 30px;
                padding-bottom: 5px;
                cursor: pointer;
            }

            .select-qzuki-or-hero {
                border-bottom: 3px solid #f8e51a;
            }

            .allQzukis {
                margin-top: 5px;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
                grid-gap: 0 2%;
            }

            .allHeroes {
                margin-top: 5px;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
                grid-gap: 0 2%;

                .hero {
                    width: 100%;
                }
            }
        }

        .header-User .header-User-Left .User h1,
        .header-User .header-User-Left .User .twitter-share span,
        .header-User .header-User-Left .treasure h1,
        .header-User .header-User-Right .badge-title h1,
        .header-User .header-User-Right .badge-title .ant-btn,
        .banners .banners-header h1,
        .banners .banners-header .Cherish .ant-btn,
        .banners .banners-header .ant-btn,
        .QZUKI h1 {
            font-family: Resource-Heavy;
        }
    }
}

@keyframes gradient {
    from {
        background-position: left;
    }

    to {
        background-position: right;
    }
}

@media (max-width:950px) {
    .Personal-Center-overflow {
        .Personal-Center {
            .header-banner {
                .ant-btn {
                    font-size: 12px;
                    transform: scale(0.7);
                    transform-origin: bottom right;
                }
            }

            .header-User {
                display: block;
                margin-top: 20px;

                .header-User-Left {
                    width: 100%;

                    .User {
                        h1 {
                            font-size: 1.5rem;

                            img {
                                width: 15px;
                            }
                        }

                        .wallet {
                            margin-top: 5px;

                            p {
                                margin-bottom: 0;
                                font-family: pixelfont;
                                font-size: 0.9rem;
                            }

                            .fuzhi {
                                width: 10px;
                                height: 10px;
                            }
                        }

                        .twitter-share {
                            transform: scale(0.7);
                            transform-origin: right;
                        }
                    }

                    .treasure {
                        margin-top: 10px;

                        h1 {
                            font-size: 1rem;
                        }
                    }
                }

                .header-User-Right {
                    width: 100%;
                    margin-left: 0;
                    margin-top: 15px;

                    .badge-title {
                        h1 {
                            font-size: 1rem;
                        }

                        .ant-btn {
                            font-size: 12px;
                            transform: scale(0.8);
                            transform-origin: right;
                        }
                    }

                    .badges {
                        width: 100%;
                        margin-top: 5px;
                    }

                    .badges-gird {
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
                    }
                }
            }

            .banners {
                margin-top: 20px;

                .banners-header {
                    h1 {
                        font-size: 1rem;
                    }

                    .Cherish {
                        .ant-btn {
                            font-size: 12px;
                            margin-right: -10px;
                            transform: scale(0.8);
                            transform-origin: left;
                        }
                    }

                    .ant-btn {
                        font-size: 12px;
                        transform: scale(0.8);
                        transform-origin: right;
                    }
                }

                .banner {
                    margin-top: 10px;
                    grid-template-columns: 1fr 1fr;
                }
            }

            .QZUKI {
                h1 {
                    font-size: 1rem;
                }

                .allQzukis {
                    margin-top: 20px;
                    grid-template-columns: 1fr 1fr;
                    grid-gap: 0 5%;
                }

                .allHeroes {
                    margin-top: 20px;
                    grid-template-columns: 1fr 1fr;
                    grid-gap: 0 5%;
                }
            }
        }
    }
}


@media (max-width:400px) {
    .Personal-Center-overflow {
        .Personal-Center {
            .header-User {
                .header-User-Left {
                    .User {
                        display: block;

                        .twitter-share {
                            margin-top: 5px;
                            transform-origin: left;
                        }
                    }
                }
            }

            .banners {
                .banner {
                    grid-template-columns: 1fr;
                }
            }
        }
    }
}
@primary-color: #1890ff;