.ant-layout {
  background: transparent;
}

.flex-inline-row {
  display: inline-flex;
  display: -webkit-inline-flex;
}

.flex-row {
  display: flex;
  display: -webkit-flex;
}

.flex-direction-column {
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-right {
  justify-content: flex-end;
}


.align-left {
  align-items: flex-start;
}

.space-between {
  justify-content: space-between;
}