.siderDrawer {
    .ant-drawer-content-wrapper {
        .ant-drawer-content {
            .ant-drawer-wrapper-body {
                .ant-drawer-header {
                    .ant-drawer-extra {
                        margin-right: 15px;
                    }
                }

                .ant-drawer-body {
                    .Adaptation {
                        background-color: transparent;

                        .ant-layout-sider-children {
                            h1 {
                                display: none;
                            }

                            .ant-menu-inline,
                            .ant-menu-vertical,
                            .ant-menu-vertical-left {
                                border-right: none;
                            }

                            .goldentext {
                                width: 98%;
                                font-weight: bolder;
                                padding: 15px 0;
                                background: linear-gradient(-45deg, #ffd580, #f0a925, #ffe56f, #f6b547);
                                background-clip: text;
                                border-bottom: 1px solid #e5e5e5;
                                -webkit-text-fill-color: transparent;

                                .ant-switch {
                                    height: 17px;
                                    margin-right: 15px;

                                    .ant-switch-handle::before {
                                        position: absolute;
                                        top: -5px;
                                        left: -2px;
                                        width: 23px;
                                        height: 23px;
                                        border-radius: 50%;
                                        content: '';
                                    }
                                }

                                .ant-switch-checked {
                                    background: linear-gradient(-45deg, #ffd580, #f0a925, #ffe56f, #f6b547);

                                    .ant-switch-handle::before {
                                        background: linear-gradient(-45deg, #ffd580, #f0a925, #ffe56f, #f6b547);
                                        content: '';
                                    }
                                }

                                .ant-switch:focus {
                                    box-shadow: none;
                                }

                            }

                            .goldentext,
                            .goldentext .ant-switch-checked,
                            .goldentext .ant-switch-checked .ant-switch-handle::before {
                                background-size: 200% 200%;
                                -webkit-animation: gradient 2s ease-in-out infinite alternate;
                                animation: gradient 2s ease-in-out infinite alternate;
                            }

                            .menu {
                                background-color: transparent;

                                .ant-menu-submenu {
                                    width: 97%;
                                    font-size: 15px;
                                    font-weight: bold;
                                    padding: 5px 0 !important;
                                    -webkit-touch-callout: none;
                                    -webkit-user-select: none;
                                    -moz-user-select: none;
                                    -ms-user-select: none;
                                    user-select: none;

                                    .icon-bold {
                                        margin-right: 15px;
                                        color: black;
                                    }

                                    .ant-menu-item {
                                        width: 100%;
                                        height: 20px;
                                        font-size: 10px;
                                        font-weight: normal;
                                        padding: 0 !important;

                                        .ant-checkbox-checked .ant-checkbox-inner,
                                        .ant-checkbox-inner::after {
                                            background-color: #c03540;
                                            border-color: #c03540 !important;
                                        }

                                        .Checkbox {
                                            width: 100%;
                                            margin-left: 10px;
                                        }
                                    }

                                    .ant-menu-title-content {
                                        color: black;

                                        >div {
                                            width: 100%;
                                            display: flex;
                                            justify-content: space-between;
                                        }
                                    }

                                    .ant-menu-submenu-title {
                                        padding: 0 !important;
                                        background-color: transparent;
                                    }

                                    .ant-menu-sub.ant-menu-inline {
                                        background-color: transparent;
                                    }
                                }

                                .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
                                    background-color: transparent;
                                }

                                .ant-menu-light .ant-menu-item:hover,
                                .ant-menu-light .ant-menu-item-active,
                                .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
                                .ant-menu-light .ant-menu-submenu-active,
                                .ant-menu-title-content:hover,
                                .ant-menu-light .ant-menu-submenu-title:hover {
                                    color: black;
                                }

                                .ant-menu-inline .ant-menu-selected::after,
                                .ant-menu-inline .ant-menu-item-selected::after {
                                    opacity: 0;
                                }

                                .filterNum {
                                    width: 20px;
                                    height: 20px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    margin-right: 20px;
                                    background-color: #f8e51a;
                                    border-radius: 50%;
                                    font-size: 10px;
                                    color: black;
                                }

                                .Scrollbars {
                                    width: calc(100% - 10px);
                                    height: auto;
                                    max-height: 250px;
                                    background-color: transparent !important;
                                    overflow-y: scroll;
                                }
                            }
                        }
                    }

                    .siderBlack {
                        background-color: transparent;

                        .ant-layout-sider-children {
                            .goldentext {
                                border-bottom: 1px solid #2e2e2e;
                            }

                            .ant-input {
                                color: white;
                            }

                            .input {
                                border-bottom: 1px solid #2e2e2e !important;
                            }

                            .menu {
                                background-color: transparent;

                                .ant-menu-submenu {
                                    color: white;
                                    border-bottom: 1px solid #2e2e2e;

                                    .icon-bold {
                                        color: white;
                                    }

                                    .ant-menu-title-content {
                                        color: white;
                                    }

                                    .ant-menu-item {
                                        .Checkbox {
                                            color: #c7c7c7;
                                        }
                                    }
                                }

                                .ant-menu-submenu-title:hover {
                                    opacity: 0.8;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@primary-color: #1890ff;