.badge {
    padding-bottom: 100%;
    position: relative;
    cursor: pointer;
    border-radius: 10px;

    .mirror-effect {
        width: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;

        img {
            width: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
    }

    .mirror-effect::before {
        content: '';
        width: 20%;
        height: 100%;
        position: absolute;
        top: 0;
        left: -20%;
        background-color: rgba(255, 255, 255, 0.502);
        z-index: 7;
    }
}

.badge:hover {
    .mirror-effect {
        width: 105%;
        top: -2.5%;
        left: -2.5%;
    }

    .mirror-effect::before {
        -webkit-animation: mirrorEffect 0.7s 1 ease-in-out;
        animation: mirrorEffect 0.7s 1 ease-in-out;
    }
}

@keyframes mirrorEffect {
    0% {
        left: -20%;
    }

    99% {
        opacity: 1;
    }

    100% {
        left: 103%;
        opacity: 0;
    }
}
@primary-color: #1890ff;