@font-face {
  font-family: "iconfont";
  /* Project id 3218586 */
  src: url('iconfont.woff2?t=1683452540233') format('woff2'),
    url('iconfont.woff?t=1683452540233') format('woff'),
    url('iconfont.ttf?t=1683452540233') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-shangyishou:before {
  content: "\e63c";
  font-size: 20px;
  color: #b5c0c0;
  cursor: not-allowed;
}

.icon-xiayishou:before {
  content: "\e63e";
  font-size: 20px;
  color: #b5c0c0;
  cursor: not-allowed;
}

.icon-Playerplay:before {
  content: "\e6b0";
  font-size: 35px;
  color: black;
  cursor: pointer;
}

.icon-Playerpause:before {
  content: "\e6b1";
  font-size: 35px;
  color: black;
  cursor: pointer;
}

.icon-yuyan:before {
  content: "\e64e";
  color: #f8e51a;
  font-size: 0.8rem;
}

.icon-xingzhuangjiehe:before {
  content: "\e679";
  color: #34c759;
  background-color: white;
  border-radius: 50%;
}

.icon-fenxiang01:before {
  content: "\e6d1";
}

.icon-fuzhi:before {
  content: "\e67b";
  color: black;
}

.icon-renwu-ren:before {
  content: "\e749";
}

.icon-qianbao:before {
  content: "\e61d";
  font-size: 20px;
}

.icon-zhiwen2x:before {
  content: "\e640";
  font-size: 18px;
}

.icon-jiahao:before {
  content: "\e721";
  font-size: 10px;
}

.icon-jianhao:before {
  content: "\e60a";
  font-size: 10px;
}

.icon-eth1:before {
  content: "\e621";
}

.icon-tuite:before {
  content: "\ee49";
}

.icon-OpenSea-fill:before {
  content: "\ed04";
}