.NFT {
    border-radius: 10px;
    text-align: center;
    font-size: 12px;
    position: relative;
    float: left;
    cursor: pointer;
    font-family: pixelfont;

    .Image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        // aspect-ratio: 1;
        padding-top: 100%;
        border-radius: 10px;
        border: none;
        box-shadow: 2px 3px 14px 0px rgba(0, 0, 0, 0.2);
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
    }

    p {
        font-size: 10px;
        color: #7d7d7d;
        margin: calc(100% + 15px) 0 -3px 0;
    }

    h3 {
        padding: 0 0 8px;
    }
}

.NFT:hover {
    .Image {
        top: -2.5%;
        left: -2.5%;
        width: 105%;
        padding-top: 105%;
    }
}
@primary-color: #1890ff;