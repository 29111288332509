@font-face {
    font-family: pixelfont;
    src: url(./Spot-Mono-Medium.ttf);
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Resource-Heavy;
    src: url(./Resource-Heavy.ttf);
    font-weight: normal;
    font-style: normal;
}

@primary-color: #1890ff;