.blackBody {
    background-color: black;

    .ant-layout-sider {
        background-color: rgba(242, 243, 247, 0.4);

        h1 {
            background: linear-gradient(-45deg, #ffd580, #f0a925, #ffe56f, #f6b547);
            background-size: 200% 200%;
            background-clip: text;
            border-bottom: 1px solid #2e2e2e;
            -webkit-text-fill-color: transparent;
            -webkit-animation: gradient 2s ease-in-out infinite alternate;
            animation: gradient 2s ease-in-out infinite alternate;
        }

        .goldentext {
            border-bottom: 1px solid #2e2e2e;
        }

        .menu {
            .ant-menu-submenu {
                color: white;

                .icon-bold {
                    color: white;
                }

                .ant-menu-title-content {
                    color: white;
                }

                .ant-menu-item {
                    .Checkbox {
                        color: #c7c7c7;
                    }
                }
            }

            .ant-menu-submenu-title:hover {
                opacity: 0.8;
            }
        }
    }

    .ant-layout-content {
        .content-header {
            border-bottom: 1px solid #2e2e2e;

            h1 {
                color: white;
            }

            h1::after {
                border-bottom: 3px solid white;
            }

            .total {
                .ant-btn {
                    border: none;
                    background-color: rgba(242, 243, 247, 0.4);
                    padding: 4px 6px;
                    border-radius: 4px;

                    .SyncOutlined {
                        font-size: 20px;
                        color: #f8e51a;
                    }
                }
            }
        }

        .filter {
            .filter-num {
                span {
                    color: black;
                    background-color: #727272;
                }
            }

            .ant-tag {
                color: white;
                border: 1px solid white;
                background-color: transparent;
            }

            .ant-tag:hover {
                background-color: transparent;
            }
        }

        .allNFTS {
            .NFT {
                h3 {
                    background: linear-gradient(-45deg, #ffd580, #f0a925, #ffe56f, #f6b547);
                    background-size: 200% 200%;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
    }
}

@keyframes gradient {
    from {
        background-position: left;
    }

    to {
        background-position: right;
    }
}