.qzuki-verse {
    width: 100%;
    height: 100vh;
    position: relative;

    .duel {
        width: 100%;
        height: calc(100% - 70px);
        margin-top: 70px;
        position: absolute;
        overflow: hidden;

        .background-duel {
            width: 120%;
            height: 100%;
            margin-left: -10%;
            object-fit: cover;
            position: absolute;
        }

        .smog {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 5;
            filter: opacity(0.8);
        }

        .role1-property,
        .role2-property,
        .role3-property,
        .role4-property,
        .role5-property,
        .role6-property,
        .role7-property,
        .role8-property {
            position: absolute;

            p,
            b {
                position: absolute;
                visibility: visible;
                opacity: 1;
                transition: all 0.3s ease-in-out;
                white-space: nowrap;
            }

            b {
                display: flex;
                align-items: center;
            }

            .tag {
                padding: 1px 20px;
                border-radius: 10px;
                background-color: rgba(255, 255, 255, 0.7);
                font-size: 0.4rem;
            }

            .tag-center {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                visibility: hidden;
                opacity: 0;
            }
        }

        .role1-property {
            width: 15%;
            padding-top: calc(15% / 0.71);
            left: 14%;
            top: 0.5%;
            background-image: url('https://qzuki.myth.art/hero/occupation/Ninja.png');
            background-size: cover;

            .tag0 {
                top: 3%;
                left: -25%;
                font-size: 0.6rem;
                font-weight: 900;
                color: white;
                font-family: Resource-Heavy;
                padding-right: 15px;
                border-bottom: 1px solid white;
            }

            .tag1 {
                top: 5%;
                left: 90%;
            }

            .tag2 {
                top: 50%;
                left: -25%;
            }

            .tag3 {
                top: 80%;
                left: 95%;
            }

            .tag4 {
                top: 80%;
                left: 0;
            }
        }

        .role2-property {
            width: 30%;
            padding-top: calc(30% * 0.91);
            left: 13%;
            top: 10%;
            background-image: url('https://qzuki.myth.art/hero/occupation/Necrolord.png');
            background-repeat: no-repeat;
            background-size: contain;
            //background-color: red;

            .tag0 {
                top: 3%;
                left: 0%;
                font-size: 0.6rem;
                font-weight: 900;
                color: white;
                font-family: Resource-Heavy;
                padding-right: 15px;
                border-bottom: 1px solid white;
            }

            .tag1 {
                top: 5%;
                left: 85%;
            }

            .tag2 {
                top: 25%;
                left: 25%;
            }

            .tag3 {
                top: 80%;
                left: 90%;
            }

            .tag4 {
                top: 70%;
                left: 20%;
            }
        }

        .role3-property {
            width: 35%;
            padding-top: calc(35% * 0.81);
            left: 13%;
            top: 28%;
            background-image: url('https://qzuki.myth.art/hero/occupation/Zen Master.png');
            background-repeat: no-repeat;
            background-size: contain;

            .tag0 {
                top: 3%;
                left: -30%;
                font-size: 0.6rem;
                font-weight: 900;
                color: white;
                font-family: Resource-Heavy;
                padding-right: 15px;
                border-bottom: 1px solid white;
            }

            .tag1 {
                top: 10%;
                left: 45%;
            }

            .tag2 {
                top: 30%;
                left: -20%;
            }

            .tag3 {
                top: 50%;
                left: 50%;
            }

            .tag4 {
                top: 60%;
                left: -10%;
            }
        }

        .role4-property {
            width: 27%;
            padding-top: calc(27% * 0.78);
            left: 13%;
            top: 42%;
            background-image: url('https://qzuki.myth.art/hero/occupation/Swordsman.png');
            background-repeat: no-repeat;
            background-size: contain;

            .tag0 {
                top: -5%;
                left: 0;
                font-size: 0.6rem;
                font-weight: 900;
                color: white;
                font-family: Resource-Heavy;
                padding-right: 15px;
                border-bottom: 1px solid white;
            }

            .tag1 {
                top: 10%;
                left: 105%;
            }

            .tag2 {
                top: 35%;
                left: 15%;
            }

            .tag3 {
                top: 50%;
                left: 100%;
            }

            .tag4 {
                top: 60%;
                left: 25%;
            }
        }

        .role5-property {
            width: 35%;
            padding-top: calc(35% * 0.83);
            left: 56%;
            top: -10%;
            background-image: url('https://qzuki.myth.art/hero/occupation/Reaper.png');
            background-repeat: no-repeat;
            background-size: contain;

            .tag0 {
                top: 15%;
                left: -5%;
                font-size: 0.6rem;
                font-weight: 900;
                color: white;
                font-family: Resource-Heavy;
                padding-right: 15px;
                border-bottom: 1px solid white;
            }

            .tag1 {
                top: 25%;
                left: 80%;
            }

            .tag2 {
                top: 35%;
                left: -15%;
            }

            .tag3 {
                top: 70%;
                left: 75%;
            }

            .tag4 {
                top: 75%;
                left: 0%;
            }
        }

        .role6-property {
            width: 24%;
            padding-top: calc(24% / 0.97);
            left: 60%;
            top: 11%;
            background-image: url('https://qzuki.myth.art/hero/occupation/Dark Swordsman.png');
            background-repeat: no-repeat;
            background-size: contain;

            .tag0 {
                top: 0;
                left: -25%;
                font-size: 0.6rem;
                font-weight: 900;
                color: white;
                font-family: Resource-Heavy;
                padding-right: 15px;
                border-bottom: 1px solid white;
            }

            .tag1 {
                top: 50%;
                left: 80%;
            }

            .tag2 {
                top: 45%;
                left: -35%;
            }

            .tag3 {
                top: 75%;
                left: 70%;
            }

            .tag4 {
                top: 75%;
                left: -20%;
            }
        }

        .role7-property {
            width: 30%;
            padding-top: calc(30% * 0.74);
            left: 56%;
            top: 26%;
            background-image: url('https://qzuki.myth.art/hero/occupation/Magister.png');
            background-repeat: no-repeat;
            background-size: contain;

            .tag0 {
                top: -20%;
                left: 30%;
                font-size: 0.6rem;
                font-weight: 900;
                color: white;
                font-family: Resource-Heavy;
                padding-right: 15px;
                border-bottom: 1px solid white;
            }

            .tag1 {
                top: 30%;
                left: 95%;
            }

            .tag2 {
                top: 50%;
                left: 10%;
            }

            .tag3 {
                top: 75%;
                left: 85%;
            }

            .tag4 {
                top: 75%;
                left: 20%;
            }
        }

        .role8-property {
            width: 23%;
            padding-top: 23%;
            left: 57%;
            top: 37%;
            background-image: url('https://qzuki.myth.art/hero/occupation/Sealmancer.png');
            background-size: cover;

            .tag0 {
                top: -15%;
                left: -2%;
                font-size: 0.6rem;
                font-weight: 900;
                color: white;
                font-family: Resource-Heavy;
                padding-right: 15px;
                border-bottom: 1px solid white;
            }

            .tag1 {
                top: 5%;
                left: 70%;
            }

            .tag2 {
                top: 20%;
                left: -40%;
            }

            .tag3 {
                top: 50%;
                left: 90%;
            }

            .tag4 {
                top: 55%;
                left: -30%;
            }
        }

        .role1-box {
            width: 14%;
            padding-top: calc(9% / 0.71);
            position: absolute;
            left: 14%;
            top: 0.5%;
            // background-color: rgba(255, 0, 0, 0.5);
        }

        .role2-box {
            width: 13%;
            padding-top: calc(19% * 0.85);
            position: absolute;
            left: 28%;
            top: 6%;
            // background-color: rgba(255, 255, 0, 0.5);
        }

        .role3-box {
            width: 15%;
            padding-top: calc(27% * 0.81);
            position: absolute;
            left: 13%;
            top: 31%;
            // background-color: rgba(81, 255, 0, 0.5);
        }

        .role4-box {
            width: 12%;
            padding-top: calc(25% * 0.78);
            position: absolute;
            left: 28%;
            top: 44%;
            // background-color: rgba(0, 255, 183, 0.5);
        }

        .role5-box {
            width: 28%;
            padding-top: calc(12% * 0.83);
            position: absolute;
            left: 56%;
            top: 0.5%;
            // background-color: rgba(0, 102, 255, 0.5);
        }

        .role6-box {
            width: 13%;
            padding-top: calc(9% / 0.97);
            position: absolute;
            left: 60%;
            top: 25%;
            // background-color: rgba(123, 0, 255, 0.5);
        }

        .role7-box {
            width: 10%;
            padding-top: calc(30% * 0.74);
            position: absolute;
            left: 73%;
            top: 25%;
            // background-color: rgba(255, 0, 238, 0.5);
        }

        .role8-box {
            width: 13%;
            padding-top: 18%;
            position: absolute;
            left: 60%;
            top: 48%;
            // background-color: rgba(255, 0, 0, 0.5);
        }

        .join-btn {
            font-size: 25px;
            padding: 2px 25px;
            background-color: #721a08;
            color: #ffd20e;
            position: absolute;
            left: 50%;
            bottom: 10%;
            transform: translateX(-50%);
            z-index: 11;
            border: 2px solid;
            border-image: linear-gradient(to right, #533320, #d6af6b, #52321f) 1;
            display: flex;
            align-items: center;
            box-shadow: 0 0 10px 2px black inset;
        }

        .shade {
            width: 100vw;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 10;
            background-color: rgba(0, 0, 0, 0.7);
            visibility: hidden;
            opacity: 0;
            transition: all 0.3s ease-in-out;
        }

        .ifShowShade {
            visibility: visible;
            opacity: 1;
        }
    }
}

@media (max-width:1000px) {
    .qzuki-verse {
        .duel {
            .background-duel {
                object-fit: cover;
            }

            .smog {
                object-fit: cover;
            }

            .role1-property {
                left: 12%;
                top: 15%;
            }

            .role2-property {
                left: 9%;
                top: 21%;
            }

            .role3-property {
                left: 11%;
                top: 35%;
            }

            .role4-property {
                left: 11%;
                top: 45%;
            }

            .role5-property {
                left: 58%;
                top: 10%;
            }

            .role6-property {
                left: 63%;
                top: 21%;
            }

            .role7-property {
                left: 58%;
                top: 33%;
            }

            .role8-property {
                left: 59%;
                top: 40%;
            }

            .role1-box {
                left: 12%;
                top: 15%;
            }

            .role2-box {
                padding-top: calc(17% * 0.91);
                left: 27%;
                top: 21%;
            }

            .role3-box {
                left: 11%;
                top: 38%;
            }

            .role4-box {
                left: 26%;
                top: 45%;
            }

            .role5-box {
                left: 58%;
                top: 16%;
            }

            .role6-box {
                left: 63%;
                top: 31%;
            }

            .role7-box {
                left: 75%;
                top: 33%;
            }

            .role8-box {
                left: 62%;
                top: 48%;
            }
        }
    }
}

@media(max-width:750px) {
    .qzuki-verse {
        .duel {
            .role1-property {
                width: 36%;
                padding-top: calc(36% / 0.71);
                left: -2%;
                top: 15%;
            }

            .role2-property {
                width: 57%;
                padding-top: calc(57% * 0.91);
                left: -7%;
                top: 31%;
            }

            .role3-property {
                width: 51%;
                padding-top: calc(51% * 0.81);
                left: -2%;
                top: 45%;
            }

            .role4-property {
                width: 48%;
                padding-top: calc(48% * 0.78);
                left: -5%;
                top: 55%;
            }

            .role5-property {
                width: 55%;
                padding-top: calc(55% * 0.83);
                left: 60%;
                top: 13%;
            }

            .role6-property {
                width: 45%;
                padding-top: calc(45% / 0.97);
                left: 63%;
                top: 28%;
            }

            .role7-property {
                width: 51%;
                padding-top: calc(51% * 0.74);
                left: 58%;
                top: 45%;
            }

            .role8-property {
                width: 44%;
                padding-top: 44%;
                left: 55%;
                top: 53%;
            }

            .role1-box,
            .role2-box,
            .role3-box,
            .role4-box,
            .role5-box,
            .role6-box,
            .role7-box,
            .role8-box {
                display: none;
            }
        }
    }
}
@primary-color: #1890ff;