.layout-header {
	width: 100%;
	//max-width: 1800px;
	height: auto;
	justify-content: space-between;
	background-color: transparent;
	z-index: 10;
	top: 0;

	.ant-btn {
		background-color: rgba(245, 240, 240, 0.8);
		color: black;
		border-radius: 4px;
		border: none;
		font-family: pixelfont;
		font-weight: 500;
		letter-spacing: 0.05em;
		font-size: 12px;
		margin-left: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	.first-btn {
		width: 100px;
		padding: 0 8px;
		background-color: transparent;
		margin-left: 0;
		box-shadow: none;
		overflow: hidden;

		.logo {
			width: 100%;
			transform: scale(1.8);
			object-fit: cover;
		}
	}

	.ant-btn:hover {
		background-color: rgba(226, 222, 222, 0.8);
		color: black;
	}

	.btn-discord,
	.btn-twitter,
	.btn-discord:hover,
	.btn-twitter:hover {
		background-color: black;
		padding: 8px;
	}

	.myth-button,
	.myth-button:hover {
		background: linear-gradient(90deg, #6390fe, #af4ef9, #fe997f);
		color: white;
	}

	.first-btn:hover {
		background-color: transparent;
	}
}

.ant-dropdown-menu {
	background-color: #f9f6f6;
	font-family: pixelfont;
	border-radius: 5px;
}

.btn {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: none;
	z-index: 10;

	.line {
		width: 25px;
		height: 2px;
		background-color: black;
		margin-top: 8px;
		position: relative;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}

	.line:first-child {
		margin-top: 0;
	}
}

.changLineStatus {
	.line:first-child {
		top: 5px;
		transform: rotate(45deg);
	}

	.line:last-child {
		top: -5px;
		transform: rotate(-45deg);
	}
}

.header-drawer {
	.ant-drawer-content {
		backdrop-filter: blur(10px);
		background-color: rgba(255, 255, 255, 0.8);
		position: absolute;
		right: 0;
	}

	p {
		margin-top: 30px;
	}

	// ant-menu
	li {
		margin: 0 !important;
	}

	.ant-menu {
		background-color: transparent;
	}

	.ant-menu-item {
		color: black;
		font-size: 20px;
		font-weight: bold;
		padding: 35px 0 !important;
		border-bottom: 1px solid #c9bdbd;
		border-right: none;

		a {
			color: black;
		}

		img {
			width: 20px;
			height: 20px;
			position: absolute;
			right: 17px;
		}
	}

	.ant-menu-submenu {
		color: black;
		width: 100%;
		font-size: 20px;
		font-weight: bold;
		padding: 12px 0;
		border-bottom: 1px solid #c9bdbd;
		border-right: none;
		overflow: hidden;

		.ant-menu-item {
			width: 100%;
			margin-left: 20px !important;
			font-size: 18px;
			border-bottom: none;
		}

		.ant-menu-submenu-title {
			padding: 0 !important;
			background-color: transparent;
		}

		.ant-menu-submenu-arrow {
			margin-right: 2px;
			transform: scale(2) !important;
		}
	}

	.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
		background-color: transparent;
	}

	.ant-menu-light .ant-menu-item:hover,
	.ant-menu-light .ant-menu-item-active,
	.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
	.ant-menu-light .ant-menu-submenu-active,
	.ant-menu-light .ant-menu-submenu-title:hover {
		color: black;
	}

	.ant-menu-inline .ant-menu-selected::after,
	.ant-menu-inline .ant-menu-item-selected::after {
		opacity: 0;
	}

	//移动端下拉框的样式
	.ant-menu-sub.ant-menu-inline {
		background-color: transparent;
	}
}

.drawer {
	.ant-drawer-content-wrapper {
		transform: translateX(0) !important;
	}
}

@media(max-width:275px) {
	.ant-menu-item {
		img {
			display: none;
		}
	}
}
@primary-color: #1890ff;