.gallery {
    overflow: hidden;

    .ant-layout-content {
        margin-top: 90px;
        padding: 0 2vw;

        .content-header {
            width: 100%;
            border-bottom: 1px solid #e5e5e5;
            padding: 7px 0;
            position: relative;

            h1 {
                height: 100%;
                font-size: 25px;
                font-weight: 900;
                margin: -7px 0 0 0;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }

            h1::after {
                content: '';
                width: 40px;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                border-bottom: 3px solid black;
            }

            .total {
                color: #7f7f7f;
                font-size: 30px;

                >span {
                    margin-top: -2px;
                    margin-right: 15px;
                }

                .ant-btn {
                    border: none;
                    background-color: black;
                    padding: 4px 6px;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .SyncOutlined {
                        font-size: 20px;
                        color: #f8e51a;
                    }
                }

                .icon {
                    display: none;
                }
            }
        }

        .filter {
            width: 100%;
            height: 30px;
            margin: 15px 0;

            .filter-num {
                font-size: 15px;
                color: #999999;
                font-weight: bolder;
                display: flex;
                align-items: center;
            }

            .Carousel {
                width: 100%;
                margin-left: 20px;

                .swiper-wrapper {
                    .swiper-slide {
                        width: auto;
                    }
                }
            }

            .ant-tag {
                border-radius: 5px;
                line-height: 25px;
                margin-top: 4px;
                padding: 2px 7px;
                cursor: pointer;
                background-color: #f6f6f6;
                border: none;

                span {
                    margin-left: 8px;
                }
            }
        }

        .allNFTS {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            grid-gap: 0 2%;
        }
    }
}

@media(max-width:1000px) {
    .gallery {
        .ant-layout-content {
            width: 100%;
            margin-top: 70px;

            .content-header {
                width: 100%;
                position: fixed;
                margin-left: calc(-2vw - 7px);
                padding: 20px 3vw 10px 3vw;
                background-color: white;
                z-index: 5;

                h1 {
                    margin-top: -18px;
                    font-size: 20px;
                }

                .total {
                    font-size: 20px;

                    >span {
                        margin-right: 5px;
                    }

                    .ant-btn {
                        background-color: transparent;
                        padding: 0;

                        .SyncOutlined {
                            font-size: 20px;
                            color: black;
                        }
                    }

                    .ant-btn:hover {
                        background-color: transparent;
                    }

                    .icon {
                        display: block;
                        position: absolute;
                        right: 3vw;
                    }
                }
            }

            .content-golden-header {
                background-color: black;
            }

            .filter {
                margin: 80px 0 20px 0;
            }

            .allNFTS {
                grid-template-columns: 1fr 1fr;
                grid-gap: 0 5%;
            }
        }
    }
}