.Scrollbars {
  width: 100%;
  height: auto;
  max-height: 250px;
  background-color: transparent;
  overflow-y: scroll;

  //去除多选框获得焦点后亮起的蓝色边框
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus+.ant-checkbox-inner {
    border: 1px solid #d9d9d9;
  }

  .ant-checkbox-checked::after{
    border: none;
  }

  .Checkbox {
    width: 100%;
    font-size: 10px;

    .ant-checkbox {
      top: 0.3em;
    }

    span:last-child {
      font-family: pixelfont;
    }
  }
}
@primary-color: #1890ff;