@import "../../themes/typography.less";
@import "../../themes/palette.less";
@import "../../themes/themeVars.less";

.connect-wallet-desktop-modal {

    .ant-modal-body {
        background-color: #ebe9e2;
        padding: 14px 40px !important;
    }

    .description {
        background-color: #ebe9e2;
        margin-bottom: 24px;
        text-align: center;
        .body-16-regular-mixin;

        span {
            .body-16-bold-mixin;
        }
    }

    .chain-buttons {
        margin-bottom: 56px;
    }

    .ant-modal-content {
        background-color: #ebe9e2;
        padding-bottom: 0 !important;

        .ant-modal-footer {
            margin-top: 22px;
            padding: 17px 100px;
            background: transparent;
            text-align: center;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }
}
@primary-color: #1890ff;