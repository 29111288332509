@font-face {
  font-family: "iconfont";
  /* Project id 3218586 */
  src: url('iconfont.woff2?t=1671702496091') format('woff2'),
    url('iconfont.woff?t=1671702496091') format('woff'),
    url('iconfont.ttf?t=1671702496091') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bg-screen:before {
  content: "\e643";
}

.icon-xinlangweibo:before {
  content: "\e600";
}

.icon-weixin:before {
  content: "\e601";
}

.icon-qq:before {
  content: "\e623";
}

.icon-eth1:before {
  content: "\e621";
}