.selectable-image {
    width: 100%;
    padding-top: calc(100% * 1.1);
    background-color: black;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    overflow: hidden;

    .career-pictures {
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: contain;
        top: 0;
        background-color: black;
        border-radius: 10px;
    }

    .picture-grays-out {
        -webkit-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
        filter: grayscale(100%);
        filter: gray;
    }
}

.occupational-status {
    box-shadow: 0 0 0 2px #f8e51a, 0 0 2px 2.5px rgba(0, 0, 0, 0.2);
}

.career-shade {
    content: '';
    width: 100%;
    height: 40%;
    position: absolute;
    bottom: 0;
    z-index: 4;
}

.collector-center-career {
    text-align: center;
    font-size: 12px;
    cursor: pointer;
    font-family: pixelfont;

    p {
        font-size: 14px;
        font-weight: bold;
        color: #7d7d7d;
        margin: 15px 0 -3px 0;
    }

    h3 {
        padding: 0 0 8px;
    }
}


@primary-color: #1890ff;