.loading {
  width: 100%;
  height: 100%;
  justify-content: center;

  .base {
    width: 12em;
    padding: 3em;
    position: absolute;
    margin-top: 6%;
    transform: rotateX(45deg) rotateZ(45deg);
    transform-style: preserve-3d;

    .cube,
    .cube:after,
    .cube:before {
      content: '';
      float: left;
      height: 2em;
      position: absolute;
      width: 2em;
    }

    /* Top */
    .cube {
      background-color: #f8e51a;
      position: relative;
      transform: translateZ(2em);
      transform-style: preserve-3d;
      transition: .25s;
      animation: anim 1s infinite;
    }

    .cube:after {
      background-color: #f2ea8d;
      transform: rotateX(-90deg) translateY(2em);
      transform-origin: 100% 100%;
    }

    .cube:before {
      background-color: #c0b112;
      transform: rotateY(90deg) translateX(2em);
      transform-origin: 100% 0;
    }

    .cube:nth-child(1) {
      animation-delay: 0.05s;
    }

    .cube:nth-child(2) {
      animation-delay: 0.1s;
    }

    .cube:nth-child(3) {
      animation-delay: 0.15s;
    }

    .cube:nth-child(4) {
      animation-delay: 0.2s;
    }

    .cube:nth-child(5) {
      animation-delay: 0.25s;
    }

    .cube:nth-child(6) {
      animation-delay: 0.3s;
    }

    .cube:nth-child(7) {
      animation-delay: 0.35s;
    }

    .cube:nth-child(8) {
      animation-delay: 0.4s;
    }

    .cube:nth-child(9) {
      animation-delay: 0.45s;
    }
  }

  @keyframes anim {
    50% {
      transform: translateZ(0.5em);
    }
  }
}
@primary-color: #1890ff;