.music {
    width: 260px;
    height: 50px;
    background-color: white;
    box-shadow: 0 0 6px 0.5px rgba(140, 141, 141, 0.5);
    position: relative;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: url('../../images/qzuki/music-background.png');
    background-size: cover;
    background-repeat: no-repeat;

    .logo {
        width: 60px;

        img {
            width: 60px;
            position: absolute;
            bottom: 0;
            left: 0;
            border-bottom-left-radius: 8px;
        }
    }

    .music-logo {
        width: 35px;
    }

    p {
        font-family: Resource-Heavy;
        font-size: 14px;
        font-weight: 600;
        margin: 0 0 0 10px;
        color: black;
    }

    .play-music {
        margin-right: 15px;
        cursor: pointer;

        img {
            width: 40px;
        }
    }
}
@primary-color: #1890ff;