@font-face {
  font-family: "iconfont";
  /* Project id  */
  src: url('iconfont.ttf?t=1668072616799') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-kanzhanlan:before {
  content: "\f009e";
}

.icon-fenxiang:before {
  content: "\f016d";
}

.icon-global:before {
  content: "\e920";
}

.icon-huojianxianxing:before {
  content: "\e614";
}

.icon-fire:before {
  content: "\e616";
}

.icon-telegram:before {
  content: "\e8db";
}

.icon-medium:before {
  content: "\e6dc";
}

.icon-ic_market:before {
  content: "\e60c";
}

.icon-copyright-circle-fil:before {
  content: "\e846";
}

.icon-twitter:before {
  font-size: 20px;
  content: "\e882";
}

.icon-instagram-fill:before {
  content: "\e88f";
}

.icon-reload:before {
  content: "\e63c";
}

.icon-24gf-forward:before {
  content: "\ea7c";
}

.icon-lock:before {
  content: "\e708";
}

.icon-pinpaiguanli:before {
  content: "\e6a4";
}

.icon-search:before {
  content: "\e706";
}

.icon-shichang:before {
  content: "\e60d";
}

.icon-file:before {
  content: "\e611";
}

.icon-shaixuan:before {
  content: "\e60b";
}

.icon-shougongzuoshi:before {
  content: "\e62d";
}

.icon-yingyong:before {
  content: "\e712";
}

.icon-sort-full:before {
  content: "\ea4b";
}

.icon-sort-up-full:before {
  content: "\ea4c";
}

.icon-sort-down-full:before {
  content: "\ea4d";
}

.icon-chevron-right:before {
  content: "\e60f";
}

.icon-chevron-left:before {
  content: "\e610";
}

.icon-chevron-up:before {
  content: "\e7a9";
}

.icon-chevron-down:before {
  content: "\e7aa";
}

.icon-file-text:before {
  content: "\e753";
}

.icon-star:before {
  content: "\e687";
}

.icon-discord:before {
  content: "\ebf8";
}

.icon-OpenSea-fill:before {
  font-size: 20px;
  content: "\ed04";
}

.icon-bg-screen:before {
  content: "\e643";
}