/* H1 – bold */
.h1-bold-mixin() {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 46px;
}

//styleName: H2 – semibold;
.h2-semibold-mixin() {
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0em;
}

//styleName: H3 – semibold;
.h3-semibold-mixin() {
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
}

//styleName: H4 – semibold;
.h4-semibold-mixin() {
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
}

//styleName: H4 – normal;
.h4-normal-mixin() {
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
}

/* H5 – semibold */
.h5-normal-mixin() {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    /* identical to box height, or 100% */
    letter-spacing: 0.06em;
}
//styleName: Body 14 – Regular;
.body-14-regular-mixin() {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
}

//styleName: Body 14 – Medium;
.body-14-medium-mixin() {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
}

//styleName: Body 14 – bold;
.body-14-bold-mixin() {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
}


//styleName: Body 16 - Medium;
.body-16-medium-mixin() {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
}

//styleName: Body 16 – Regular;
.body-16-regular-mixin() {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
}

/* Body 16 – bold */
.body-16-bold-mixin() {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
}

//styleName: Footnote 12 - description
.footnote-12-description-mixin() {
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
}

//styleName: Menu 14 – caps;
.footnote-14-caps-mixin() {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
}

/* Callout Medium */
.body-16-callout-medium() {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height, or 131% */

    letter-spacing: -0.0031em;
}

//styleName: Footnote 10 CAPS;
.footnote-10-caps-mixin() {
    font-family: Inter;
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: 11px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
}
