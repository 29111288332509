.qzukiModal {
    .ant-modal-body {
        padding: 0;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .qzukiModal-img {
            width: 50%;
            aspect-ratio: 1;

            img {
                width: 100%;
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
            }
        }


        // .qzuki-data-btn {
        //     position: absolute;


        //     // margin-left: 0;
        //     // padding: 0 8px;
        //     left: -30PX;
        //     top: 65px;
        //     width: 30px;
        //     height: 80px;
        //     img {
        //         border-top-left-radius: 7px;
        //         border-bottom-left-radius: 7px;
        //         width: 100%;
        //         height: 100%;
        //         object-fit: cover;
        //     }

        // }

        // .hero-data-btn {
        //     position: absolute;

        //     // margin-left: 0;
        //     // padding: 0 8px;
        //     left: -30PX;
        //     width: 30px;
        //     height: 120px;
        //     top: 180px;
        //     img {
        //         border-top-left-radius: 7px;
        //         border-bottom-left-radius: 7px;
        //         width: 100%;
        //         height: 100%;
        //         object-fit: cover;
        //     }
        // }

        // .amplifyA {

        //     position: absolute;

        //     // margin-left: 0;
        //     // padding: 0 8px;
        //     left: -36PX;
        //     top: 65px;
        //     width: 36px;
        //     height: 96px;

        //     img {
        //         border-top-left-radius: 7px;
        //         border-bottom-left-radius: 7px;
        //         width: 100%;
        //         height: 100%;
        //         object-fit: cover;
        //     }

        // }
        // .amplifyB {
        //     width: 36px;
        //     height: 144px;
        //     border-radius: 7px;
        //     position: absolute;
        //     left: -36PX;
        //     top: 160px;

        //     img {
        //         border-top-left-radius: 7px;
        //         border-bottom-left-radius: 7px;
        //         width: 100%;
        //         height: 100%;
        //         object-fit: cover;
        //     }
        // }

        .q-h-btn {
            position: absolute;
            left: -40px;
            margin-top: 50px;

            .qzuki,
            .hero {
                width: 40px;
                height: calc(40px * 2.3);
                margin-bottom: 30px;
                position: relative;
                cursor: pointer;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .hero {
                height: calc(40px * 3.2);

                .not-optional {
                    -webkit-filter: grayscale(100%);
                    -o-filter: grayscale(100%);
                    -moz-filter: grayscale(100%);
                    -ms-filter: grayscale(100%);
                    filter: grayscale(100%);
                    filter: gray;
                    cursor: not-allowed;
                }
            }
        }

        .magnify {
            transform: scale(1.2);
            transform-origin: right;
        }


        .information-bar {
            width: 50%;
            aspect-ratio: 1;
            padding: 0 3%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .qzuki-data-header {
                width: 100%;
                margin-top: 25px;
                padding: 10px 15px;
                background-color: rgba(52, 49, 65, 0.2);
                border-radius: 10px;
                box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2) inset,
                    1px 1px 1px rgba(255, 255, 255, 0.2);

                div:first-child {
                    >span {
                        font-size: 0.6rem;
                        font-weight: 600;
                        margin-right: 8px;
                    }

                    .qzuki-text {
                        font-size: 0.5rem;
                        margin: 0;
                    }

                    p:last-child {
                        font-weight: 700;
                        font-size: 0.5rem;
                        margin: 0 0 0 8px;
                    }
                }

                .qzuki-icon {
                    a {
                        width: 25px;
                        height: 25px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 5px;
                        margin-left: 8px;
                        background-color: rgba(52, 49, 65, 0.2);
                        box-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2),
                            0.5px 0.5px 0.5px rgba(255, 255, 255, 0.2) inset;
                    }

                    img {
                        width: 20px;
                    }
                }
            }


            .qzuki-content {
                overflow: hidden;

                .content {
                    width: calc((100% - 0.5rem) / 2);
                    float: left;
                    padding: 4px 0 4px 15px;
                    font-size: 12px;
                    background-color: rgba(52, 49, 65, 0.2);
                    border-radius: 20px;
                    margin-top: 15px;
                    margin-left: 0.5rem;
                    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2) inset,
                        0.5px 0.5px 1px rgba(255, 255, 255, 0.2);

                    span {
                        display: inline-block;
                        transform: scale(0.8);
                        transform-origin: left;
                        font-family: pixelfont;
                    }

                    p {
                        width: 150%;
                        margin: 0;
                        font-weight: bolder;
                        transform: scale(0.9);
                        transform-origin: left;
                        margin-top: -5px;
                    }
                }

                .content:first-child,
                .content:nth-child(2) {
                    margin-top: 0;
                }

                .content:nth-child(2n+1) {
                    margin-left: 0;
                }
            }

            .collector {
                max-width: fit-content;
                padding: 10px 5px;
                font-size: 12px;
                background-color: rgba(52, 49, 65, 0.2);
                border-radius: 10px;
                margin-bottom: 20px;
                cursor: pointer;
                box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2) inset,
                    1px 1px 1px rgba(255, 255, 255, 0.2);

                div:first-child {
                    margin: 0 7px;
                }

                p {
                    margin: 0;
                    transform: scale(0.9);
                    transform-origin: left;
                    word-wrap: break-word;
                    font-family: pixelfont;
                }
            }
        }

        .hero-information-backgruond {
            width: 100%;
            border-radius: 20px;
            background: linear-gradient(#312e43d7, #312e43d7), url(../../images/qzuki-story/duel.jpg);
            background-size: cover;
            background-position: -550px;
            display: flex;
            align-items: center;

            .hero-img {
                width: 50%;
                padding-top: 50%;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    position: absolute;
                    top: 0;
                    left: 0;
                }

            }

            .hero-information {
                width: 50%;
                padding: 0 3%;

                .heroId {
                    width: 100%;
                    // height: 50px;
                    padding: 10px 10px;
                    background-color: rgba(45, 36, 57, 0.823);
                    border-radius: 10px;
                    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2) inset,
                        1px 1px 1px rgba(255, 255, 255, 0.2);
                    color: white;

                    >span {
                        padding: 0;
                        font-size: 0.6rem;
                        font-weight: 600;
                        margin-right: 8PX
                    }

                    .qzuki-text {
                        padding: 0;
                        font-size: 0.5rem;
                        margin: 0;
                        color: rgba(255, 255, 255, 0.6);
                    }

                    p:last-child {
                        font-weight: 700;
                        font-size: 0.5rem;
                        margin: 0 0 0 8px;
                    }

                }

                .heroName,
                .heroOccupation {
                    width: 48%;
                    // height: 50px;
                    margin-top: 40px;
                    background-color: rgba(41, 33, 51, 0.823);
                    border-radius: 50px;
                    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2) inset,
                        1px 1px 1px rgba(255, 255, 255, 0.2);

                    p:last-child {
                        margin: -3px 0 0 0;
                    }
                }

                .heroName {
                    margin-right: 4%;
                }

                .heroStory {
                    width: 100%;
                    margin-top: 15px;
                    position: relative;
                    background-color: rgba(37, 29, 46, 0.823);
                    border-radius: 15px;
                    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2) inset,
                        1px 1px 1px rgba(255, 255, 255, 0.2);

                    p:last-child {
                        width: 100%;
                        word-break: break-all;
                        font-size: 13px;
                        margin: 0;
                        text-indent: 2em;
                    }
                }

                .heroName,
                .heroOccupation,
                .heroStory {
                    padding: 5px 15px;
                    color: white;

                    .qzuki-text {
                        font-size: 12px;
                        transform: scale(0.9);
                        transform-origin: left;
                        font-family: pixelfont;
                        color: rgba(255, 255, 255, 0.6);
                        margin: 0;
                    }

                    p:last-child {
                        font-weight: 600;
                        font-size: 12px;
                    }
                }

            }
        }
    }

    .ant-modal-content,
    .ant-modal-content .ant-modal-body {
        border-radius: 20px;
    }
}

@media(max-width:930px) {
    .qzukiModal {
        .ant-modal-body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;


            .qzukiModal-img {
                width: 100%;

                img {
                    border-top-right-radius: 20px;
                    border-bottom-left-radius: 0;
                }
            }


            .information-bar {
                width: 100%;
                aspect-ratio: 0;

                .qzuki-content {
                    margin: 20px 0;
                }
            }

            .q-h-btn {
                display: none;
            }

            .Carousel {
                width: 100%;

                .swiper-wrapper {
                    .swiper-slide {
                        width: auto;
                        height: auto;

                        .hero-information-backgruond {
                            display: block;
                            background-position: center;

                            .hero-img {
                                width: 100%;
                                padding-top: 100%;
                            }

                            .hero-information {
                                width: 100%;
                                padding: 20px 3%;

                                .heroName,
                                .heroOccupation {
                                    margin-top: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media(max-width:670px) {
    .qzukiModal {
        .ant-modal-body {
            .information-bar {
                .qzuki-data-header {
                    div:first-child {
                        >span {
                            font-size: 0.9rem;
                        }

                        .qzuki-text,
                        p:last-child {
                            font-size: 0.8rem;
                        }
                    }
                }

                .qzuki-content {
                    .content {
                        float: none;
                    }

                    .content:nth-child(n) {
                        margin-top: 0;
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

@media (max-width:360px) {
    .qzukiModal {
        .ant-modal-body {
            .information-bar {
                .collector {
                    p {
                        transform: scale(0.8);
                    }
                }
            }
        }
    }
}
@primary-color: #1890ff;