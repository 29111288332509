.ant-modal-wrap {
    overflow: hidden;
}

.badge-modal {
    .ant-modal-content {
        border-radius: 20px;
        background-color: #ece9e2;

        .ant-modal-header {
            border-top-right-radius: 20px;
            border-top-left-radius: 20px;
            border: none;
            background-color: transparent;

            .ant-modal-title {
                font-weight: 900;
            }
        }

        .ant-modal-body {
            display: flex;
            align-items: center;
            padding: 0 24px;
            position: relative;

            .badge-modal-left {
                width: 50%;
                height: 400px;

                .badge-gird {
                    width: 98%;
                    padding: 3px 0;
                    margin: 0 auto;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                    grid-gap: 3% 3%;
                }
            }

            .badge-modal-right {
                width: 50%;
                height: 100%;
                text-align: center;

                img {
                    width: 30%;
                    border-radius: 12px;
                }

                .img-rare {
                    -webkit-animation: heartRare 1s ease-in-out infinite alternate;
                    animation: heartRare 1s ease-in-out infinite alternate;
                }

                .img-Legendary {
                    -webkit-animation: heartLegendary 1s ease-in-out infinite alternate;
                    animation: heartLegendary 1s ease-in-out infinite alternate;
                }

                .img-Mythical {
                    -webkit-animation: heartMythical 1s ease-in-out infinite alternate;
                    animation: heartMythical 1s ease-in-out infinite alternate;
                }


                p {
                    margin: 20px 0 0 0;
                    font-weight: 900;
                    font-size: 0.5rem;
                }

                .badge-tag {
                    width: 80px;
                    text-align: center;
                    background: linear-gradient(90deg, #6390fe, #af4ef9, #fe997f);
                    color: white;
                    border-radius: 20px;
                    padding: 3px 10px;
                    font-size: 12px;
                    margin: 8px 0 15px 0;
                }

                .badge-information {
                    background-color: #dbd8d0;
                    padding: 10px 25px;
                    border-radius: 5px;
                    font-size: 12px;
                    text-align: center;
                }
            }
        }
    }
}

.banner-modal {
    .ant-modal-content {
        border-radius: 20px;
        background-color: #ece9e2;

        .ant-modal-header {
            border-top-right-radius: 20px;
            border-top-left-radius: 20px;
            border: none;
            background-color: transparent;
            padding-bottom: 5px;

            .ant-modal-title {
                font-weight: 900;
            }
        }

        .ant-modal-body {
            padding: 0 24px;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .modal-banners {
                width: 100%;
                margin-top: 10px;

                .banners-modal-gird {
                    width: 98%;
                    margin: 0 auto;
                    margin-top: 5px;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-gap: 0 15px;
                }

                .ant-btn {
                    margin-top: 10px;
                    background: linear-gradient(90deg, #6390fe, #af4ef9, #fe997f);
                    border: none;
                    color: white;
                    border-radius: 10px;
                    font-weight: bold;
                    margin-left: 50%;
                    transform: translateX(-50%);
                }
            }

            .ant-pagination {
                margin: 10px 0 10px 0;

                .ant-pagination-item-active {
                    background-color: #f8e71b;
                    border: none;

                    a {
                        color: black;
                    }
                }

                li a,
                li .ant-pagination-item-link {
                    border: none;
                }

                li:hover {
                    border: none;
                }
            }
        }
    }
}

.treasure-modal {
    .ant-modal-content {
        border-radius: 20px;
        background-color: #ece9e2;

        .ant-modal-close-x {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .ant-modal-header {
            border-top-right-radius: 20px;
            border-top-left-radius: 20px;
            border: none;
            background-color: transparent;
            padding: 10px 24px;

            .ant-modal-title {
                font-weight: 900;
            }
        }

        .ant-modal-body {
            padding: 0 24px;

            .Exhibit {
                width: 80%;
                margin: 0 auto;

                .modal-display-nft {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                    grid-gap: 0 2%;
                }

                .ant-btn {
                    margin-top: 10px;
                    background: linear-gradient(90deg, #6390fe, #af4ef9, #fe997f);
                    border: none;
                    color: white;
                    border-radius: 10px;
                    font-weight: bold;
                    margin-left: 50%;
                    transform: translateX(-50%);
                }
            }

            .selection-area {
                margin-top: 10px;
                padding-bottom: 10px;

                .selection-btn {

                    .Carousel {
                        width: 100%;

                        .swiper-slide {
                            width: auto;

                            .ant-btn {
                                background-color: #dedbd4;
                                margin-right: 10px;
                                border: none;
                                font-size: 12px;
                                border-radius: 10px;
                                font-weight: 900;
                                opacity: 0.6;
                                color: black;
                            }

                            .selectBtn {
                                background-color: #f8e51a;
                                opacity: 1;
                            }
                        }
                    }

                    .ant-btn {
                        background-color: #dedbd4;
                        margin-right: 10px;
                        border: none;
                        font-size: 12px;
                        border-radius: 10px;
                        font-weight: 900;
                        opacity: 0.6;
                        color: black;
                    }

                    .selectBtn {
                        background-color: #f8e51a;
                        opacity: 1;
                    }
                }

                .selection-nft {
                    margin-top: 5px;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .gird-treasure-nft {
                        width: 99%;
                        margin: 0 auto;
                        padding: 5px 0;
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                        grid-gap: 10px 10px;

                        img {
                            width: 100%;
                            border-radius: 10px;
                            cursor: pointer;
                        }

                        .selected-status {
                            box-shadow: 0 0 0 2px #f8e51a, 0 0 2px 2.5px rgba(0, 0, 0, 0.2);
                        }
                    }

                    .ant-pagination {
                        width: auto;
                        margin-top: 10px;
                        display: flex;

                        .ant-pagination-item-active {
                            background-color: #f8e71b;
                            border: none;

                            a {
                                color: black;
                            }
                        }

                        li a,
                        li .ant-pagination-item-link {
                            border: none;
                        }
                    }
                }
            }
        }
    }
}

.badge-modal,
.banner-modal,
.treasure-modal {
    .ant-modal-content {
        .ant-modal-close-x {
            width: 50px;
            height: 50px;
            line-height: 50px;
        }
    }
}

@keyframes heartRare {
    0% {
        box-shadow: 0 0 5px 2px #99b5e7;
    }

    100% {
        box-shadow: 0 0 15px 3px #99b5e7;
    }
}

@keyframes heartLegendary {
    0% {
        box-shadow: 0 0 5px 2px #f5cf79;
    }

    100% {
        box-shadow: 0 0 15px 3px #f5cf79;
    }
}

@keyframes heartMythical {
    0% {
        box-shadow: 0 0 5px 2px #d59aec;
    }

    100% {
        box-shadow: 0 0 15px 3px #d59aec;
    }
}

@media(max-width:950px) {
    .badge-modal {
        .ant-modal-content {
            .ant-modal-body {
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
                justify-content: center;

                .badge-modal-right {
                    width: 100%;
                    margin-top: 20px;

                    p {
                        font-size: 0.7rem;
                    }
                }

                .badge-modal-left {
                    width: 100%;
                    height: 50vw;
                    position: relative;
                    padding: 3vw 0;

                    .badge-gird {
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
                    }
                }
            }
        }
    }

    .treasure-modal {
        .ant-modal-content {
            .ant-modal-body {
                .Exhibit {
                    width: 100%;

                    .ant-btn {
                        font-size: 12px;
                    }
                }

                .selection-area {
                    .selection-nft {
                        .gird-treasure-nft {
                            width: 98%;
                            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                        }
                    }

                    .ant-pagination {
                        transform: scale(0.9);
                    }
                }
            }
        }
    }

    .banner-modal {
        .ant-modal-content {
            .ant-modal-body {
                .modal-banners {
                    width: 100%;
                    margin-top: 5px;

                    .banners-modal-gird {
                        grid-template-columns: 1fr 1fr;
                        grid-gap: 0 15px;
                    }
                }

                .ant-pagination {
                    display: flex;
                    transform: scale(0.8);
                }
            }
        }
    }
}

@media (max-width:450px) {
    .banner-modal {
        .ant-modal-content {
            .ant-modal-body {
                .modal-banners {
                    .banners-modal-gird {
                        grid-template-columns: 1fr;
                        grid-gap: 10px 10px;
                    }
                }
            }
        }
    }
}
@primary-color: #1890ff;