.noData {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        text-align: center;
        margin: 10px 0 0 0;
        font-weight: bolder;
    }
}

@media (max-width:700px) {
    .noData {
        p {
            font-size: 0.8rem !important;
        }
    }
}
@primary-color: #1890ff;