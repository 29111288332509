.home-page {
    width: 100%;
    //max-width: 1800px;
    height: 100%;
    background-color: #f8e51a;
    background-image: url('../images/qzuki/background.jpg');
    background-size: cover;
    display: inline-flex;
    position: absolute;

    .home-page-left {
        width: 65%;
        height: 100%;
        padding: 0 40px;
        background-color: rgba(248, 229, 26, 0.8);
        position: relative;
        z-index: 2;

        .home-page-text {
            width: 100%;
            position: absolute;
            top: 55%;
            opacity: 0;
            transform: translateY(-50%);
            padding: 0 40px;
            transition: 0.5s all ease-in-out;

            h1 {
                color: black;
                font-size: 2rem;
                line-height: 2rem;
                margin-bottom: 15px;
                font-weight: 700;

                span {
                    background: linear-gradient(90deg, #9b4af7, #e943f8, #fe868c);
                    background-clip: text;
                    background-size: 200% 200%;
                    color: transparent;
                    -webkit-animation: gradient 2s ease-in-out infinite alternate;
                    animation: gradient 2s ease-in-out infinite alternate;
                }
            }

            p {
                font-size: 0.5rem;
                color: hsla(0, 0%, 0%, 0.8);
            }

            .ant-btn {
                margin-top: 20px;
                font-weight: 700;
                border-radius: 20px;
                background-color: white;
                color: black;
                padding: 0 30px;
                border: none;
                box-shadow: 0 0 5px #ccbd13;
            }

            .toQzukiVerse {
                margin-left: 20px;
                background: linear-gradient(90deg, #6390fe, #af4ef9, #fe997f);
                color: white;
            }
        }

        .transition {
            position: absolute;
            top: 50%;
            opacity: 1;
        }

        .MobileCarousel {
            width: 100%;
            margin-bottom: 20px;

            .Mobile-swiper-img {
                width: 100px;
                aspect-ratio: 0.75;
                border-top-left-radius: 50% calc(50% * 0.75);
                border-top-right-radius: 50% calc(50% * 0.75);
                border-bottom-left-radius: 50% calc(50% * 0.75);
                border-bottom-right-radius: 50% calc(50% * 0.75);
                border: 3px solid #d3ca75;
                object-fit: cover;
            }

            .swiper-wrapper .swiper-slide {
                width: auto;
            }
        }
    }

    .home-page-left::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        backdrop-filter: blur(8px);
        z-index: -1;
    }

    .home-page-right {
        width: 46%;
        height: 100%;
        background-color: #f7e419;
        margin-left: -11%;
        padding-right: 2%;
        z-index: 1;

        .Carousel {
            width: calc((100% - 1.5rem)/4);
            height: 100%;

            .swiper-img {
                width: 100%;
                aspect-ratio: 0.75;
                border-top-left-radius: 50% calc(50% * 0.75);
                border-top-right-radius: 50% calc(50% * 0.75);
                border-bottom-left-radius: 50% calc(50% * 0.75);
                border-bottom-right-radius: 50% calc(50% * 0.75);
                border: 3px solid #d3ca75;
                object-fit: cover;
            }

            .swiper-wrapper .swiper-slide {
                height: auto;
            }
        }

    }

    .home-page-right,
    .home-page-left {
        .swiper .swiper-wrapper {
            -webkit-transition-timing-function: linear;
            /*之前是ease-out*/
            -moz-transition-timing-function: linear;
            -ms-transition-timing-function: linear;
            -o-transition-timing-function: linear;
            transition-timing-function: linear;
        }
    }
}

@keyframes gradient {
    from {
        background-position: left;
    }

    to {
        background-position: right;
    }
}

@media (max-width:800px) {
    .home-page {
        .home-page-left {
            width: 100%;
            justify-content: space-between;

            .home-page-text {
                width: auto;
            }
        }

        .home-page-right {
            display: none;
        }
    }
}

@media (max-width:600px) {
    .home-page {
        .home-page-left {
            padding: 0 20px;

            .home-page-text {
                padding: 0 20px;
            }
        }
    }
}
@primary-color: #1890ff;