.gallery {
    position: absolute;
    width: 100vw;
    //max-width: 1800px;
    height: 100%;
    padding: 0 0 0 20px;

    .ant-layout-sider {
        margin-top: 90px;
        height: auto;
        background-color: rgba(242, 243, 247, 0.8);
        border-radius: 10px;
        position: relative;

        h1 {
            font-size: 25px;
            font-weight: 900;
            border-bottom: 1px solid #e5e5e5;
            padding: 10px 20px;
            margin: 0;
        }

        .ant-menu-inline,
        .ant-menu-vertical,
        .ant-menu-vertical-left {
            border-right: none;
        }

        .goldentext {
            width: 100%;
            font-weight: bolder;
            font-size: 13px;
            padding: 20px 0;
            border-bottom: 1px solid #e5e5e5;
            background-image: url('../images/qzuki/background.jpg');
            background-size: cover;

            .goldentext-text {
                padding-left: 20px;
                background: linear-gradient(90deg, #5ca3fe, #cd54d8, #d24bdf, #fea476);
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }

            .goldentext-text::before {
                content: '';
                width: 30px;
                padding: 32px 0;
                background: linear-gradient(90deg, #ffffff6c, #ffffff, #ffffff6c);
                filter: blur(2px);
                position: absolute;
                left: -30px;
                transition: 0.5s all ease-in-out;
                z-index: 5;
            }

            .ant-switch {
                height: 17px;
                margin-right: 20px;

                .ant-switch-handle::before {
                    position: absolute;
                    top: -5px;
                    left: -2px;
                    width: 23px;
                    height: 23px;
                    border-radius: 50%;
                    content: '';
                }
            }

            .ant-switch-checked {
                background: linear-gradient(-45deg, #ffd580, #f0a925, #ffe56f, #f6b547);

                .ant-switch-handle::before {
                    background: linear-gradient(-45deg, #ffd580, #f0a925, #ffe56f, #f6b547);
                    content: '';
                }
            }

            .ant-switch:focus {
                box-shadow: none;
            }

        }

        .goldentext:hover {
            .goldentext-text::before {
                -webkit-animation: mirrorEffect 0.7s 1 ease-in-out;
                animation: mirrorEffect 0.7s 1 ease-in-out;
            }
        }

        .goldentext .goldentext-text,
        .goldentext .ant-switch-checked,
        .goldentext .ant-switch-checked .ant-switch-handle::before {
            background-size: 200% 200%;
            -webkit-animation: gradient 2s ease-in-out infinite alternate;
            animation: gradient 2s ease-in-out infinite alternate;
        }

        .menu {
            background-color: transparent;
            padding-bottom: 100px;

            .ant-menu-submenu {
                width: 100%;
                font-size: 13px;
                font-weight: bold;
                //border-bottom: 1px solid #e5e5e5;
                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;

                .icon-bold {
                    margin-right: 10px;
                    color: #999999;
                    background-color: #f8e51a;
                    border-radius: 50%;
                }

                .ant-menu-item {
                    width: 100%;
                    height: 20px;
                    font-size: 10px;
                    font-weight: normal;
                    padding: 0 !important;

                    .ant-checkbox-checked .ant-checkbox-inner,
                    .ant-checkbox-inner::after {
                        background-color: #c03540;
                        border-color: #c03540;
                    }
                }

                .ant-menu-title-content {
                    color: black;

                    >div {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                    }
                }

                .ant-menu-submenu-title {
                    margin: 0;
                    padding: 25px 10px 25px 20px !important;
                    background-color: transparent;
                }

                .ant-menu {
                    padding: 0 0 0 20px !important;
                }

                .ant-menu-sub.ant-menu-inline {
                    background-color: transparent;
                }
            }

            .ant-menu-submenu:hover {
                .ant-menu-submenu-title {
                    background-color: #f8e51a;
                }

                .icon-bold {
                    color: black;
                }
            }

            .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
                background-color: transparent;
            }

            .ant-menu-light .ant-menu-item:hover,
            .ant-menu-light .ant-menu-item-active,
            .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
            .ant-menu-light .ant-menu-submenu-active,
            .ant-menu-title-content:hover,
            .ant-menu-light .ant-menu-submenu-title:hover {
                color: black;
            }

            .ant-menu-inline .ant-menu-selected::after,
            .ant-menu-inline .ant-menu-item-selected::after {
                opacity: 0;
            }

            .filterNum {
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 20px;
                background-color: #f8e51a;
                border-radius: 50%;
                font-size: 10px;
                color: black;
            }

            .filterNum::before {
                content: '';
                width: 5px;
                height: 100%;
                padding: 25px 0;
                position: absolute;
                left: 0;
                background-color: #f8e51a;
            }
        }
    }
}

@keyframes gradient {
    from {
        background-position: left;
    }

    to {
        background-position: right;
    }
}

@keyframes mirrorEffect {
    0% {
        left: -30px;
    }

    99% {
        opacity: 1;
    }

    100% {
        left: 100%;
        opacity: 0;
    }
}

@media(max-width:1000px) {
    .gallery {
        padding: 0 6px;

        .ant-layout-sider {
            display: none
        }
    }
}

@import '../styles/galleryContent.less';
@import '../styles/golden.less';
@primary-color: #1890ff;