.hero {
    width: 100%;
    height: 100%;
    position: relative;

    .hero-story-generation {
        width: 100%;
        margin-top: 70px;

        .battlefield {
            width: 100%;
            height: 100%;
            position: fixed;
            object-fit: cover;
        }

        .shade {
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 2;
            background-color: rgba(0, 0, 0, 0.7);
            opacity: 0.7;
        }

        .hero-content {
            width: 100%;
            height: 100%;
            padding: 0 10vw;
            z-index: 3;
            position: relative;
            overflow: hidden;

            .hero-select {
                margin-top: 50px;

                h1 {
                    color: white;
                    font-weight: 900;
                    margin-bottom: 20px;
                }

                .select {
                    display: flex;
                    align-items: center;

                    .selected-graph {
                        width: 18%;
                        padding-top: 18%;
                        background-color: #f8e51a;
                        background-image: url('../images/qzuki/logo.png');
                        background-size: cover;
                        border-radius: 10px;
                        position: relative;

                        img {
                            width: 100%;
                            position: absolute;
                            top: 0;
                            border-radius: 10px;
                            object-fit: cover;
                        }
                    }

                    .selectable-editing-area {
                        margin-left: 30px;
                        width: calc(82% - 30px);
                        position: relative;

                        p {
                            color: white;
                            font-size: 0.8rem;
                            font-weight: bolder;
                        }

                        .prev,
                        .next {
                            position: absolute;
                            color: white;
                            z-index: 4;
                            font-size: 30px;
                            top: 50%;
                            transform: translateY(-50%);
                            cursor: pointer;
                        }

                        .next {
                            right: 0px;
                        }

                        .Carousel {
                            width: 88%;

                            .swiper-wrapper {
                                .swiper-slide {
                                    width: auto;
                                    padding: 2px;

                                    .selectable-image {
                                        width: 8vw;
                                        padding-top: 8vw;
                                        background-color: #f8e51a;
                                        background-image: url('../images/qzuki/logo.png');
                                        background-size: cover;
                                        border-radius: 10px;
                                        cursor: pointer;
                                        box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.2) inset;
                                        position: relative;

                                        .Mobile-swiper-img {
                                            width: 100%;
                                            position: absolute;
                                            top: 0;
                                            object-fit: cover;
                                            border-radius: 10px;
                                            box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.2) inset;
                                        }
                                    }

                                    .qzuki-status {
                                        box-shadow: 0 0 0 2px #f8e51a, 0 0 2px 2.5px rgba(0, 0, 0, 0.2);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .name-input {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 60px;

                p {
                    color: white;
                    font-size: 0.7rem;
                    font-weight: 900;
                    margin-bottom: 15px;
                }

                .ant-input-affix-wrapper {
                    width: 40%;
                    background-color: rgba(138, 137, 137, 0.8);
                    border: none;
                    border-radius: 5px;
                    padding-right: 0;
                    padding-top: 10px;
                    padding-bottom: 10px;

                    .ant-input {
                        width: 100%;
                        background-color: transparent;
                        color: white;
                        text-align: center;
                        font-weight: bold;
                    }
                }
            }

            .choosing-a-profession {
                width: 100%;
                margin-top: 70px;
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;

                .career-name {
                    display: inline-flex;
                    align-items: center;

                    p {
                        color: white;
                        font-size: 0.7rem;
                        margin: 0 20px 0 0;
                    }

                    .ant-tag {
                        padding: 2px 9px;
                        border-radius: 5px;
                    }
                }

                .activation-conditions {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 10px;
                    width: 100%;
                    position: relative;

                    p {
                        color: white;
                        font-size: 0.5rem;
                        margin: 0 15px 0 0;
                    }

                    .trait {
                        display: inline-flex;
                        align-items: center;
                        max-width: 60%;

                        .Carousel {
                            width: auto;

                            .swiper-wrapper {
                                .swiper-slide {
                                    width: auto;

                                    .ant-tag {
                                        padding: 2px 12px;
                                        border-radius: 5px;
                                        border: 1px solid white;
                                        font-weight: bold;
                                    }
                                }
                            }
                        }

                    }
                }

                .introduce {
                    margin-top: 10px;
                    color: white;
                    text-indent: 2em;
                }

                .optional-profession {
                    width: 100%;
                    margin-top: 10px;
                    position: relative;

                    .occupation-prev,
                    .occupation-next {
                        position: absolute;
                        color: white;
                        z-index: 4;
                        font-size: 30px;
                        top: 50%;
                        transform: translateY(-50%);
                        cursor: pointer;
                    }

                    .occupation-next {
                        right: -45px;
                    }

                    .occupation-prev {
                        left: -45px;
                    }

                    .Carousel {
                        width: 100%;

                        .swiper-wrapper {
                            .swiper-slide {
                                width: calc((100% - 60px) / 5);
                                padding: 2px;
                            }
                        }
                    }
                }
            }

            .heroic-character {
                width: 100%;
                margin-top: 50px;

                p {
                    color: white;
                    font-size: 0.7rem;
                    font-weight: bolder;
                }

                .characters {
                    width: 100%;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                    grid-gap: 1vw 1vw;

                    .character {
                        background-color: rgba(138, 137, 137, 0.8);
                        color: white;
                        padding: 5px 0;
                        border-radius: 5px;
                        text-align: center;
                        cursor: pointer;
                    }

                    .selected-status {
                        background-color: #f8e51a;
                        color: black;
                    }
                }
            }

            .generate-a-story {
                width: 100%;
                margin-top: 50px;

                p {
                    font-size: 0.6rem;
                    color: white;
                    font-weight: bold;
                    margin-bottom: 15px;
                }

                .model {
                    width: 100%;
                    display: flex;

                    div {
                        background-color: transparent;
                        border: none;
                        color: white;
                        box-shadow: none;
                        margin-right: 30px;
                        padding-bottom: 5px;
                        cursor: pointer;
                    }

                    .mode-selected {
                        border-bottom: 3px solid #f8e51a;
                    }
                }

                .seize-a-seat {
                    width: 100%;
                    padding-top: calc(80vw * 0.35);
                    margin-top: 10px;
                    background-color: rgba(138, 137, 137, 0.8);
                    border-radius: 15px;
                    position: relative;

                    .explain {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translateX(-50%) translateY(-50%);
                        z-index: 3;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        p {
                            color: white;
                            font-size: 0.5rem;
                            font-weight: normal;
                            margin-bottom: 25px;
                        }

                        .ant-btn {
                            background-color: #f8e51a;
                            border-radius: 5px;
                            color: black;
                            font-size: 12px;
                            font-weight: bold;
                        }
                    }
                }

                .ai {
                    margin-top: 20px;

                    .select {
                        color: white;
                        font-size: 0.6rem;
                        font-weight: 600;
                        font-family: pixelfont;
                        margin-left: -10px;
                    }

                    .spin {
                        width: 100%;
                        height: calc((80vw * 0.3));
                        margin-top: 10px;
                        background-color: rgba(138, 137, 137, 0.8);
                        border-radius: 15px;
                        color: white;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .ant-spin {
                            display: flex;
                            align-items: center;

                            .ant-spin-dot {
                                .ant-spin-dot-item {
                                    background-color: white;
                                }
                            }
                        }

                        p {
                            margin: 0 0 0 20px;
                        }
                    }

                    .ai-input {
                        width: 100%;
                        height: calc((80vw * 0.3));
                        margin-top: 10px;
                        background-color: rgba(138, 137, 137, 0.8);
                        border-radius: 15px;
                        border: none;
                        resize: none;
                        color: white;
                        padding: 10px;
                    }
                }

                .free-input {
                    width: 100%;
                    height: calc(80vw * 0.35);
                    margin-top: 10px;
                    background-color: rgba(138, 137, 137, 0.8);
                    border-radius: 15px;
                    border: none;
                    resize: none;
                    color: white;
                    padding: 10px;
                }
            }

            .start {
                background: linear-gradient(90deg, #6390fe, #af4ef9, #fe997f);
                color: white;
                font-weight: bolder;
                padding: 4px 25px;
                border: none;
                border-radius: 5px;
                margin: 50px 50%;
                transform: translateX(-50%);
            }
        }
    }
}

@media(max-width:950px) {
    .hero {
        .hero-story-generation {
            .hero-content {
                padding: 0 5%;

                .hero-select {
                    margin-top: 25px;

                    h1 {
                        text-align: center;
                    }

                    .select {
                        display: block;

                        .selected-graph {
                            width: 25%;
                            padding-top: 25%;
                            margin-left: 50%;
                            transform: translate(-50%);
                        }

                        .selectable-editing-area {
                            margin-left: 0;
                            width: 100%;

                            p {
                                margin: 20px 0;
                                text-align: center;
                                font-size: 1rem;
                            }

                            .prev,
                            .next {
                                display: none;
                            }

                            .Carousel {
                                width: 100%;

                                .swiper-wrapper {
                                    .swiper-slide {
                                        .selectable-image {
                                            width: 12vw;
                                            padding-top: 12vw;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .name-input {
                    margin-top: 20px;

                    p {
                        font-size: 1rem;
                    }

                    .ant-input-affix-wrapper {
                        width: 70%;

                        .ant-input {
                            font-size: 12px;
                        }
                    }
                }

                .choosing-a-profession {
                    margin-top: 30px;

                    .occupation-prev,
                    .occupation-next {
                        display: none;
                    }
                }

                .heroic-character {
                    margin-top: 30px;

                    p {
                        font-size: 1rem;
                    }

                    .characters {
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                    }
                }

                .generate-a-story {
                    margin-top: 30px;

                    p {
                        font-size: 1rem;
                        margin-bottom: 5px;
                    }

                    .seize-a-seat {
                        padding-top: calc(80vw * 0.55);

                        .explain {
                            width: 100%;

                            p {
                                font-size: 0.6rem;
                                margin-bottom: 15px;
                            }
                        }
                    }

                    .ant-input {
                        height: calc(80vw * 0.55);
                    }
                }
            }
        }
    }
}

@media (max-width:600px) {
    .hero {
        .hero-story-generation {
            .hero-content {
                .heroic-character {
                    .characters {
                        grid-template-columns: 1fr 1fr 1fr 1fr;
                    }
                }
            }
        }
    }
}

@media (max-width:450px) {
    .hero {
        .hero-story-generation {
            .hero-content {
                .heroic-character {
                    .characters {
                        grid-template-columns: 1fr 1fr 1fr;
                    }
                }
            }
        }
    }
}
@primary-color: #1890ff;