.banner-img {
    border-radius: 10px;
    position: relative;
    cursor: pointer;

    .overlapping-drawing {
        width: 100%;
        position: relative;
        transform: scaleX(0.96) scaleY(0.9);
        transform-origin: top right;
        border-radius: 10px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        z-index: 3;
    }

    .overlapping-drawing:nth-child(2) {
        transform-origin: center;
        z-index: 2;
    }

    .overlapping-drawing:last-child {
        transform-origin: bottom left;
        z-index: 1;
    }

    .overlapping-drawing:nth-child(2),
    .overlapping-drawing:last-child {
        position: absolute;
        left: 0;
        opacity: 0.4;
    }

    .img {
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    }

    .banner-information {
        margin: 10px 0 calc(1.5rem / 2) 0;

        p {
            font-weight: bold;
            margin: 0;
            display: flex;
            align-items: center;
        }

        .tag {
            width: 80px;
            padding: 2px 10px;
            color: white;
            border-radius: 20px;
            font-weight: 500;
            text-align: center;
        }

        .tag-rare {
            background-color: #1963ed;
        }

        .tag-golden {
            background: linear-gradient(90deg, #fbd006, #fae41a, #fbd006);
            color: black;
        }

        .tag-mythical {
            background: linear-gradient(90deg, #6390fe, #af4ef9, #fe997f);
        }
    }

    .banner-num {
        width: 32px;
        height: 32px;
        border-radius: 5px;
        background-color: black;
        transform: scale(0.75);
        transform-origin: bottom right;
        color: white;
        border: none;
        margin: 0;
        position: absolute;
        bottom: 18%;
        right: 10px;
        z-index: 5;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .polygon-logo {
        width: 25px;
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 5;
    }
}
@primary-color: #1890ff;