@import "./styles/iconfont/iconfont.css";
@import '~antd/dist/antd.less';

* {
	margin: 0;
	padding: 0;
}

body {
	width: 100% !important;
	//max-width: 1800px;
	//margin: 0 auto;
	overflow: visible !important;
}

.sign {
	.ant-modal-content {
		.ant-modal-body {
			padding: 20px 24px;

			.ant-modal-confirm-body {
				.ant-modal-confirm-title {
					font-family: pixelfont;
					font-weight: 100;
				}

				.ant-modal-confirm-content {
					font-weight: 100;
					padding: 10px 0;
					text-align: left;
					margin-left: 38px;
				}
			}

			.ant-modal-confirm-btns {
				margin: 0;
			}
		}
	}
}

.music-position {
	position: fixed;
	left: 3vw;
	bottom: 3vw;
	z-index: 20;
}
@primary-color: #1890ff;